<template>
  <div v-show="!loading" class="p-4">
    <div v-if="!showRegistrationForm">
      <div
        class="card-header d-flex align-items-center justify-content-between mb-4"
      >
        <h2>Halo, Guest!</h2>
        <button
          class="btn btn-primary"
          type="button"
          @click="openRegistrationForm"
        >
          Saya Tertarik
        </button>
      </div>
      <div class="card-body">
        <p>
          Terima kasih atas ketertarikan Anda terhadap Solar Panel (PLTS),
          berikut adalah perhitungan produk Solar Panel yang sesuai dengan
          kebutuhan Anda
        </p>
        <div class="row mb-5">
          <div class="col-md-6">
            <h4 class="fw-bold">Nilai Investasi</h4>
            <h2 class="text-success fw-bold mb-4">
              {{ toIDR(price) }}
            </h2>
          </div>
          <div class="col-md-6">
            <h4 class="fw-bold">Penghematan</h4>
            <h2 class="text-success fw-bold mb-4">
              {{ toIDR(savings) }}/bulan
            </h2>
            <!-- <v-progress-circular
            :rotate="-90"
            :size="170"
            :width="20"
            :value="savingsPercent"
            color="#00b700"
          >
            <h1 class="mb-0 text-secondary fw-bold">{{ savingsPercent }}%</h1>
          </v-progress-circular> -->
          </div>
        </div>

        <div class="row mb-5">
          <h4 class="fw-bold">Break Even Period</h4>
          <h2 class="text-success fw-bold">{{ paybackPeriod }} tahun</h2>
          <LineChart
            :investment-value="price"
            :savings="savings"
            :payback-period="paybackPeriod"
          />
        </div>

        <h4 class="fw-bold">Produk</h4>
        <div class="row">
          <div
            v-for="(item, index) in products"
            :key="`product-card-${index}`"
            class="col-sm-6 col-md-4 mb-3 mb-md-0"
          >
            <div class="card">
              <img
                :src="item.product_img"
                :alt="item.name"
                class="product-img"
              />
              <div class="card-body">
                <span class="badge bg-primary mb-2">{{ item.category }}</span>
                <h4 class="card-title">
                  {{ item.name }}
                </h4>
                <p class="mb-2">Qty: {{ item.quantity }}</p>
                <p v-show="item.performance_warranty" class="mb-2">
                  Garansi: {{ item.performance_warranty }} tahun
                </p>

                <!-- <img :src="item.vendor_img" alt="vendor" class="vendor-img" /> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <h3>Form Pemesanan PV Rooftop</h3>
      <p>
        Mohon isi data berikut dengan benar, team kami akan menghubungi Anda
        untuk melakukan site survey
      </p>

      <div class="row">
        <div class="col-sm-12 col-md-7 col-lg-6">
          <form>
            <div class="mb-3">
              <label for="Nama" class="form-label mb-2">Nama Lengkap</label>
              <input
                type="text"
                class="form-control"
                name="name"
                v-model="customerName"
                autocomplete="off"
                required
              />
            </div>

            <div class="mb-3">
              <label for="No Telepon" class="form-label mb-2"
                >No. Telepon</label
              >
              <input
                type="text"
                class="form-control"
                name="phone_number"
                autocomplete="off"
                v-model="customerPhone"
                required
              />
            </div>

            <div class="mb-3">
              <label for="No Telepon" class="form-label mb-2"
                >ID Pelanggan PLN</label
              >
              <input
                type="text"
                class="form-control"
                name="pln_customer_id"
                v-model="plnCustomerId"
                autocomplete="off"
                required
              />
            </div>

            <button
              class="btn btn-primary"
              type="button"
              @click="updateCustomerProposal"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import toIDR from "@kevingio/number-utils/dist/helpers/toIDR";

export default {
  name: "CalculatorResult",
  components: {
    LineChart: () =>
      import(/* webpackChunkName: "line-chart" */ "./components/LineChart.vue"),
  },
  data: () => ({
    products: [],
    whatsappUrl: null,
    price: "",
    paybackPeriod: null,
    savings: "",
    savingsPercent: null,

    loading: true,
    showRegistrationForm: false,

    customerName: null,
    customerPhone: null,
    plnCustomerId: null,
    calculatorId: null,
  }),
  mounted() {
    const savedData = localStorage.getItem("atw-calculator-res");
    if (!savedData) {
      this.$router.push("/");
    } else {
      const {
        calculator_id,
        product_list,
        price,
        savings,
        savings_percent,
        whatsapp_url,
        payback_period,
      } = JSON.parse(savedData);
      this.calculatorId = calculator_id;
      this.products = product_list;
      this.price = price;
      // const paybackPeriod = Math.ceil(price / (savings * 12));
      this.paybackPeriod = payback_period;
      this.savings = savings;
      this.savingsPercent = savings_percent;
      this.whatsappUrl = whatsapp_url;

      // show UI
      this.loading = false;
    }
  },
  methods: {
    openRegistrationForm() {
      this.showRegistrationForm = true;
    },
    async updateCustomerProposal() {
      if (
        !this.customerName ||
        !this.customerPhone ||
        !this.calculatorId ||
        !this.plnCustomerId
      ) {
        this.$swal("Warning", "Mohon lengkapi form data pemesar", "warning");
        return;
      }

      this.$swal({
        title: "Mohon Tunggu",
        text: "Kami sedang menghitung estimasi biaya untuk pemasangan Solar Panel Anda",
        imageUrl: require("../../assets/loader.gif"),
        showConfirmButton: false,
        allowOutsideClick: false,
      });

      const formData = new FormData();
      formData.append("customer_name", this.customerName);
      formData.append("customer_phone_number", this.customerPhone);
      formData.append("pln_customer_id", this.plnCustomerId);
      formData.append("api_token", process.env.VUE_APP_SALES_TOKEN);

      try {
        const res = await axios.post(
          `${process.env.VUE_APP_API_BASE_URL}/sales/send-offer/${this.calculatorId}`,
          formData,
          {
            headers: {
              Accept: "/*",
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );

        const resData = res.data;

        if (resData.status == "success") {
          this.$swal(
            "Berhasil",
            "Terima kasih atas ketertarikan anda mengenai instalasi PLTS",
            "success"
          ).then(() => {
            this.$router.push("/");
          });
        } else {
          this.$swal(
            "Warning",
            "Maaf kami sedang menerima banyak antrian, mohon coba kembali nanti",
            "error"
          );
        }
      } catch (error) {
        this.$swal(
          "Warning",
          "Maaf kami sedang menerima banyak antrian, mohon coba kembali nanti",
          "error"
        );
      }
    },
    toIDR,
  },
};
</script>

<style scoped>
.product-img {
  max-width: 250px;
  width: 100%;
  height: 250px;
  object-fit: contain;
  display: block;
  margin: auto;
}

.vendor-img {
  display: block;
  margin: auto;
  max-width: 100%;
  height: auto;
  margin-top: 20px;
}
</style>
