<template>
  <MapForm
    :mode="mode"
    :error-message="errorMessage"
    @submit="createQuotation"
  />
</template>

<script>
import axios from "axios";

export default {
  name: "SolarCalculator",
  components: {
    MapForm: () =>
      import("./components/MapForm" /* webpackChunkName: "map-form" */),
  },
  props: {
    mode: {
      type: String,
      default: "ongridres",
    },
  },
  data: () => ({
    errorMessage: null,
  }),
  methods: {
    async createQuotation({
      area,
      pln_bill_monthly,
      location,
      canopy_width,
      canopy_length,
    }) {
      this.$swal({
        title: "Mohon Tunggu",
        text: "Kami sedang menghitung estimasi biaya untuk pemasangan Solar Panel Anda",
        imageUrl: require("../../assets/loader.gif"),
        showConfirmButton: false,
        allowOutsideClick: false,
      });

      this.errorMessage = null;
      const formData = new FormData();
      formData.append("calculator_code", this.mode);
      formData.append("city_lvl_2", location);
      formData.append("bill_pln", pln_bill_monthly);
      formData.append("rooftop_area", area);

      const token = process.env.VUE_APP_SALES_TOKEN;
      if (token) {
        formData.append("api_token", token);
      }

      if (this.mode == "canopyres") {
        formData.append("canopy_width", canopy_width);
        formData.append("canopy_length", canopy_length);
      }

      try {
        const res = await axios.post(
          `${process.env.VUE_APP_API_BASE_URL}/app/calculator`,
          formData,
          {
            headers: {
              Accept: "/*",
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        const resData = res.data;
        if (resData.status == "success") {
          localStorage.setItem(
            "atw-calculator-res",
            JSON.stringify(resData.data)
          );
          this.$swal(
            "Selamat",
            "Proses estimasi biaya sudah selesai",
            "success"
          );
          this.$router.push("/calculator-result");
        } else {
          this.errorMessage = resData.error_message;
        }
      } catch (error) {
        if (error.response) {
          const errMessage = error.response.data.error_message;
          this.$swal("Warning", errMessage, "error");
          return;
        }

        this.$swal(
          "Warning",
          "Maaf kami sedang menerima banyak antrian, mohon coba kembali nanti",
          "error"
        );
      }
    },
  },
};
</script>

<style>
</style>
