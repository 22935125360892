import Vue from 'vue';
import VueRouter from 'vue-router';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

import App from './App.vue';
import { routes } from './routes';

Vue.use(VueRouter);
Vue.use(VueSweetalert2);

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
    libraries: 'places,drawing',
    language: 'id'
  },
  // Demonstrating how we can customize the name of the components
  installComponents: true
});

Vue.config.productionTip = false;

Vue.component('GoogleMap', VueGoogleMaps.Map);

const router = new VueRouter({
  routes,
  mode: 'history'
});

new Vue({
  router,
  render: (h) => h(App)
}).$mount('#app');
